import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
import "./S3Uploader.css"; // Add a CSS file for better styling

const S3Uploader = () => {
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState("");
  const [productName, setProductName] = useState('');
  const [productBrand, setProductBrand] = useState('');
  const [messageType, setMessageType] = useState('');
  const [activeTab, setActiveTab] = useState("upload");
  const [gridData, setGridData] = useState([]); // To store fetched data
  const [loadingData, setLoadingData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  
  

  // AWS S3 Configuration using environment variables
  const s3 = new AWS.S3({
    accessKeyId: 'AKIA2VPJSHXGR2OPMG55', // AWS Access Key from .env
    secretAccessKey: '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', // AWS Secret Key from .env
    region: 'us-east-1', // AWS Region from .env
  });

  const lambda = new AWS.Lambda({
    region: "us-east-1", // Replace with your region
    credentials: new AWS.Credentials({
      accessKeyId: 'AKIA2VPJSHXGR2OPMG55',
      secretAccessKey: 'i2uFcV45myawy2UH0Obo4AyOF/SYXkskMjP7YE5J',
    }),
  });

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  const handleUpload = async () => {
    if (!file || !productName || !productBrand) {
      setMessageType('error');
      setMessage("Please select a file to upload.");
      return;
    }

    setUploading(true);
    setMessageType('');
    setMessage("");
    console.log("Bucket Name:", 'knowyourfood');

    const params = {
      Bucket: 'knowyourfood', // S3 bucket name from .env
      Key: `uploads/${file.name}`, // The path and file name in S3
      Body: file,
      ContentType: file.type,
      Tagging: `productName=${encodeURIComponent(productName)}&productBrand=${encodeURIComponent(productBrand)}`,
      Metadata: {
        'product-name': productName,
        'product-brand': productBrand,
      },
      //ACL: "public-read", // Optional: Set permissions
    };

    try {
      const result = await s3.upload(params).promise();
      setUploading(false);
      setMessageType('success');
      setMessage(`File uploaded successfully!`);
    } catch (error) {
      setUploading(false);
      setMessageType('error');
      console.error("Upload error details:", error);
      setMessage(`Failed to upload file. Error: ${error.message}`);
    } finally {
      setUploading(false);
    }
  };

  // Fetch Grid Data from Lambda
  const fetchGridData = async () => {
    setLoadingData(true);

    const params = {
      FunctionName: "kyf_getall_ingredients", // Replace with your Lambda function name
      Payload: JSON.stringify({}), // Pass any required payload
    };

    try {
      const response = await lambda.invoke(params).promise();
      const responseData = JSON.parse(response.Payload); // Parse Lambda response
      const body = JSON.parse(responseData.body); // Parse the body from stringified JSON

      // Check if data is an array before setting state
      if (Array.isArray(body.data)) {
        setGridData(body.data);
      } else {
        console.error("Expected an array in data but got:", body.data);
        setGridData([]);
      }
    } catch (error) {
      console.error("Error fetching data from Lambda:", error);
      setMessageType('error');
      setMessage("Failed to fetch data from Lambda.");
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    if (activeTab === "view") {
      fetchGridData(); // Fetch grid data when the View Data tab is active
    }
  }, [activeTab]);

  return (
    <div>
      {/* Sidebar */}
      <div className="sidebar">
        <div className="logo">Know Your Food</div>
        <a
          href="#"
          className={activeTab === "upload" ? "active" : ""}
          onClick={() => setActiveTab("upload")}
        >
          Upload Image
        </a>
        <a
          href="#"
          className={activeTab === "view" ? "active" : ""}
          onClick={() => setActiveTab("view")}
        >
          View Data
        </a>
      </div>

      {/* Main Content Area */}
      <div className="app-container">
        {activeTab === "upload" && (
          <>
            <header className="app-header">
              <h1>Welcome to Know Your Food App</h1>
            </header>
            <main className="app-main">
              <section className="app-description">
                <p>This app allows you to upload your product image with Nutrition and Ingredient Value and it would provide the Health Summary. Response will take around 5 mins</p>
              </section>

              {/* Product Info Section */}
              <div className="product-info">
                <div className="input-group">
                  <label htmlFor="product-brand">Product Brand:</label>
                  <input
                    type="text"
                    id="product-brand"
                    value={productBrand}
                    onChange={(e) => setProductBrand(e.target.value)}
                    className="input-field"
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="product-name">Product Name:</label>
                  <input
                    type="text"
                    id="product-name"
                    value={productName}
                    onChange={(e) => setProductName(e.target.value)}
                    className="input-field"
                  />
                </div>
              </div>

              {/* Upload Section */}
              <div className="uploader-container">
                <h2 className="uploader-title">Upload Nutrition Fact Image</h2>
                <div className="uploader-content">
                  <input
                    type="file"
                    onChange={handleFileChange}
                    className="uploader-input"
                  />
                  <button
                    onClick={handleUpload}
                    disabled={uploading}
                    className={`uploader-button ${uploading ? "uploader-button-disabled" : ""}`}
                  >
                    {uploading ? "Uploading..." : "Upload"}
                  </button>
                </div>
                {message && (
                  <p className={`uploader-message ${messageType === 'success' ? 'success' : 'error'}`}>
                    {message}
                  </p>
                )}
              </div>
            </main>
          </>
        )}

        {activeTab === "view" && (
          <div className="view-data">
            <h2>View Data</h2>
            {loadingData ? (
              <p>Loading data...</p>
            ) : (
              <div className="grid-container">
                {gridData.length === 0 ? (
                  <p>No data available.</p>
                ) : (
                  gridData.map((item, index) => (
                    <div key={index} className="grid-item">
                      {/* Render Image */}
                      <img src={item.s3_url} alt={`Uploaded Product ${index}`} className="grid-item-image" onClick={() => openModal(item.s3_url)}/>
                      <p>{item.OpenAI_Response}</p>
                      {/* Add more data fields as needed */}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        )}


        {/* Modal to display the clicked image */}
        {isModalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <span className="close-btn" onClick={closeModal}>&times;</span>
              <img src={selectedImage} alt="Modal View" className="modal-image" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default S3Uploader;
