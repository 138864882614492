import React from "react";
import S3Uploader from "./S3Uploader";

function App() {
  return (
    <div className="App">
      <S3Uploader />
    </div>
  );
}

export default App;
